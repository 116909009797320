
import React, { useState, useEffect } from 'react';
import { getFunctions, httpsCallable } from "firebase/functions";
import { Dialog, DialogContent } from '@mui/material';

import { BsPlay } from 'react-icons/bs';
import ReactPlayer from 'react-player/lazy'
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    Pagination, IconButton
} from '@mui/material';
const ReportedPosts = ({ ...props }) => {
    const [posts, setPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [selectedPost, setSelectedPost] = useState(null);
    const functions = getFunctions();

    const pageSize = 10; // Number of posts per page  
    const [isOpen, setIsOpen] = useState(false);
    const [pageTokens, setPageTokens] = useState([null]);
    const [loading, setLoading] = useState(false);

    const handleOpenPopup = (post) => {
        setSelectedPost(post)
        setIsOpen(true);
    };

    const handleClosePopup = () => {
        setIsOpen(false);
    };
    const fetchPosts = async (page) => {
       

        try {
            console.log('page',page);
            const callableReturnMessage = httpsCallable(functions, 'getPaginatedReportedPosts');
            const pageToken = pageTokens[page - 1];
            console.log('page',{ pageSize, pageToken });

            const response = await callableReturnMessage({ pageSize, pageToken });
            console.log('page',response);

            setPosts(response.data.reportedPosts);
            setTotalPages(Math.ceil(response.data.total / pageSize));
            // Update pageTokens state to keep track of page tokens for each page
            setPageTokens((prevTokens) => {
                const updatedTokens = [...prevTokens];
                updatedTokens[page] = response.data.nextPageToken;
                return updatedTokens;
            });
            setLoading(false);

        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    useEffect(() => {
        fetchPosts(1);
    }, []);
    const handlePageChange = (event, page) => {
        setCurrentPage(page);
        fetchPosts(page);
    };


    return (
        <div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Title</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Type  </TableCell>
                            <TableCell>Media  </TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {posts?.map((post) => (
                            <TableRow key={post.id}>
                                <TableCell>{post.postTitle}</TableCell>
                                <TableCell>{post.reportReasonDescription}</TableCell>
                                <TableCell>{post.postType === 'i' ? 'Image' : "Video"}</TableCell>
                                <TableCell>{post.postType === 'i' ? <Zoom>
                                    {post.postmediaUrl && <img src={post.postmediaUrl} width={30} height={30} alt="mediaUrl" style={{ cursor: 'zoom-in', borderRadius: '50%', marginTop: '10px' }} />}
                                </Zoom> : <div>
                                    <div>
                                        <IconButton
                                            color="primary"
                                            onClick={() => handleOpenPopup(post)}

                                        >
                                            <BsPlay />
                                        </IconButton>
                                    </div>
                                    <Dialog BackdropProps={{ style: { backgroundColor: 'transparent' }, }}
                                        open={isOpen} onClose={handleClosePopup}>

                                        <DialogContent>
                                            {selectedPost?.postmediaUrl &&
                                                <ReactPlayer
                                                    className='react-player'
                                                    url={selectedPost.postmediaUrl}
                                                    pip={false}
                                                    playing={false}
                                                    controls={true}
                                                    loop={false}

                                                />}
                                        </DialogContent>
                                    </Dialog>

                                </div>
                                }</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="pagination">
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    variant="outlined" shape="rounded"
                    color="primary" />
            </div>
        </div>
    );
};
export default ReportedPosts
